<div class="login_layout d-flex flex-column container-fluid">
  <div class="row">
    <div class="col-lg-6 vh-100 order-1 d-lg-block d-none site-info box-shadow-right">
      <div class="d-flex align-items-center h-100">
          <div class="inner d-flex flex-column align-items-center">
            <img class="w-50" src="https://s3.carvia.tech/dl/ddg6d588-c2e6-4b23-9bc0-86e3b0ffb3bg/4385e-4dd06d3.png" alt="Carvia Tech - AI-Brains">
            <div class="content">
              <h4>AI Brains</h4>
              <ul>
                <li>Revolutionize Your Content Discovery: Unleash the Power of AI Search!</li>
                <li>Unlock Hidden Insights: AI-Powered Content Search for Businesses</li>
                <li>Navigate Your Content Universe with AI: Find What Matters, Faster</li>
                <li>Transform Your Search Experience: AI-Driven Content Discovery Made Simple</li>
              </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 vh-100 order-2 login_wrap bg-white">
      <div class="d-flex align-items-center h-100 justify-content-center">
        <div class="inner d-flex flex-column align-items-center">
          <img class="w-50 mb-2 d-lg-none d-block" src="https://s3.carvia.tech/dl/ddg6d588-c2e6-4b23-9bc0-86e3b0ffb3bg/4385e-4dd06d3.png" alt="Carvia Tech - AI-Brains">
          <div class="content">
            <div class="form-item">
              <div *ngIf="visible1">
                <form [formGroup]="formMobile" (keyup.enter)="getLoginOtp()">
                  <div class="d-flex justify-content-center mt-4">
                    <button class="w-100" mat-raised-button color="primary" type="button" [disabled]="OtpLoading" (click)="login()">
                      <span *ngIf="!OtpLoading">Login</span>
                      <mat-spinner class="ms-1" color="primary" *ngIf="OtpLoading" diameter="20" mode="indeterminate"></mat-spinner>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="form-item">
              <div *ngIf="visible2" class="mobile">
                <form [formGroup]="formOtp" (keyup.enter)="login()">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="floatingInput1" disabled="true" value="{{mobileNo}}">
                    <a class="d-flex justify-content-end" type="button" (click)="visible1=true; visible2=false">
                      <span>ReEnter Mobile</span>
                    </a>
                  </div>
                  <div class="form-floating mb-3">
                    <input [appAutofocus]="visible2" type="text" formControlName="otp" class="form-control" maxlength="6"
                            minlength="6"
                            pattern="[0-9]*" id="floatingInput2" placeholder="OTP" required>
                    <label for="floatingInput1" class="ml-2 login-labels">OTP</label>
                  </div>
                  <div *ngIf="loginError" class="alert-danger bg-transparent">
                    <p>{{ loginError }}</p>
                  </div>
                  <div class="d-flex justify-content-center mt-4">
                    <button class="w-100" mat-raised-button color="primary" type="button"  [disabled]="showLoader" (click)="login()">
                      <span *ngIf="!showLoader">Submit</span>
                      <mat-spinner class="ms-1" color="primary" *ngIf="showLoader" diameter="20" mode="indeterminate"></mat-spinner>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
