import {Component, inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router, RouterModule} from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {KeycloakService} from "keycloak-angular";
import {UserDataService} from "./core/services/userDataService";
import {KeycloakProfile} from "keycloak-js/lib/keycloak";
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule,MatInputModule,
    MatFormFieldModule,],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  public isLoggedIn = false;
  private readonly keycloakService: KeycloakService = inject(KeycloakService);
  private userDataService: UserDataService = inject(UserDataService);
  public userProfile: KeycloakProfile | null = null;

  async ngOnInit() {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();

    if (this.isLoggedIn) {
      const token = await this.keycloakService.getToken();
      this.userDataService.getUserInfo(token);
      this.userDataService.user$.subscribe((user) => {
        if (user) {
          this.userProfile = user;
          this.userDataService.setUser(user);
        }
      });
    }

  }

  public login() {
    this.keycloakService.login();
  }
  public logout() {
    this.keycloakService.logout();
  }
}
