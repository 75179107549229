import { Observable } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { SessionService } from '../../services/session/session.service';
import { RedirectUrlService } from '../../services/redirectUrl-service/redirectUrl.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn} from '@angular/router';
import {KeycloakService} from "keycloak-angular";

@Injectable({
  providedIn: 'root'
})

class PermissionsService {

  constructor(
    private router: Router,
    private _sessionService: SessionService,
    private keycloakService:KeycloakService) {}

  async canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = await this.keycloakService.isLoggedIn();
    if (!isLoggedIn) {
      await this.keycloakService.login();
      return false;
    }
    return true;

  }

}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(PermissionsService).canActivate(next, state);
}
