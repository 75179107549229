import {Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth/auth.guard';
import {LoginComponent} from './pages/login/login.component';
import {LoginRedirectGuard} from './core/guards/login/login-redirect.guard';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, title: 'Login', canActivate: [LoginRedirectGuard] },
  {
    path: 'brain',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/layout/layout.route').then((r) => r.LAYOUT_ROUTE),
  },
  { path: '**', redirectTo: 'login' }
];
