import { Observable } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { SessionService } from '../../services/session/session.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateFn } from '@angular/router';
import {KeycloakService} from "keycloak-angular";

@Injectable({
  providedIn: 'root'
})
class LoginService {

  constructor(private _sessionService: SessionService, private router: Router, private keycloakService: KeycloakService) {}

  async canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = await this.keycloakService.isLoggedIn();
    if (isLoggedIn) {
      this.router.navigateByUrl('/brain/dashboard');
      return false;
    }
    return true;
  }
}

export const LoginRedirectGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(LoginService).canActivate(next, state);
};
